<template>
  <div class="bg-white rounded-2xl m-4 pb-10">
    <div class="text-right pt-3 mx-4">
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="handleBackSubOrgList"
        class="cursor-pointer"
      >
        <path
          d="M6 18.4998L17.9998 6.5"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.9998 18.4998L6 6.5"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="grid grid-cols-3 gap-4">
      <div>
        <form class="flex flex-col items-center justify-center">
          <img class="w-72 h-64 object-contain mb-3 mt-4" :src="thumb_file" alt="Logo org" />
          <label for="file-upload">
            <div
              class="bg-primary text-white cursor-pointer flex items-center justify-center rounded-lg h-10 w-24 px-3 py-2 focus:outline-none"
            >
              <div>Đổi Logo</div>
            </div>
          </label>
          <input
            id="file-upload"
            class="d-none"
            name="upload_cont_img"
            type="file"
            accept="image/*"
            @change="onSelectedThumbFile($event)"
          />
        </form>
      </div>
      <div class="col-span-2 m-4">
        <div class="grid grid-cols-2 gap-4 mb-3">
          <div>
            <label class="text-xl text-black font-medium ml-3 mb-2">Cơ quan Chủ quản</label>
            <input
              class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 focus:outline-none w-full"
              placeholder="VD: Bộ Y Tế"
              v-model="org.authority_in_charge"
            />
          </div>
          <div>
            <label class="text-xl text-black font-medium ml-3 mb-2 required">Tên tổ chức</label>
            <input
              class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 focus:outline-none w-full"
              placeholder="Nhập tên"
              v-model="org.name"
            />
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mb-3">
          <div>
            <label class="text-xl text-black font-medium ml-3 mb-2">Số điện thoại</label>
            <input
              class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 focus:outline-none w-full"
              placeholder="Nhập số điện thoại"
              v-model="org.contact_point"
            />
          </div>
          <div>
            <label class="text-xl text-black font-medium ml-3 mb-2 required">Tên lãnh đạo</label>
            <div
              class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 pt-2.5 focus:outline-none w-full"
            >{{owner && owner.name}}</div>
          </div>
        </div>

        <div class="mb-3">
          <label class="text-xl text-black font-medium ml-3 mb-2 required">Địa chỉ</label>
          <input
            class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 focus:outline-none w-full"
            placeholder="Nhập địa chỉ"
            v-model="org.address"
          />
        </div>
      </div>
    </div>
    <div class="text-right mx-4">
      <button
        @click="updateOrg"
        class="bg-primary text-white rounded-lg h-10 w-24 px-3 py-2 border-0 focus:outline-none"
      >Cập nhật</button>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import _ from 'lodash'
export default {
  name: 'OrganizationUpdate',
  data () {
    return {
      appUtils,
      org: '',
      thumb_file: '',
      belongToOrg: '',
      listOption: null,
      owner: '',
      imgPut: ''
    }
  },
  mounted () {
    let self = this
    // self.getOrgList()
    self.getOrg()
  },
  watch: {
    listOption (value) {
      const data = value.find(item => {
        return item.id === this.org.parent_id
      })
      this.belongToOrg = data?.id
    }
  },
  methods: {
    async getOrg () {
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getOrg(this.$route.params.id)
      this.org = res.data
      this.thumb_file = appUtils.getImageURL(this.org.logo)
      const data = this.listOption?.find(item => {
        return item.id === this.org.parent_id
      })
      this.belongToOrg = data?.id
      this.owner = this.org.owner
    },
    async getOrgList () {
      const res = await this.$rf.getRequest('DoctorRequest').getOrgList()
      this.listOption = res.data.data
    },
    onSelectedThumbFile (event) {
      this.imgPut = event.target.files[0]
      this.thumb_file = URL.createObjectURL(event.target.files[0])
    },
    async putOrgList () {
      let params = {
        name: this.org.name,
        parent_id: this.belongToOrg,
        address: this.org.address,
        contact_point: this.org.contact_point,
        authority_in_charge: this.org.authority_in_charge
      }
      if (this.imgPut) {
        params = {
          ...params,
          logo: this.imgPut
        }
      }
      const formData = new FormData()
      for (const key in params) {
        if (!_.isNil(params[key])) formData.set(key, params[key])
      }
      const data = await this.$rf
        .getRequest('DoctorRequest')
        .putOrgList(this.org.id, formData)
      appUtils.setGlobalOrg(data.data)
      this.$toast.open({
        message: 'Cập nhật Organization thành công',
        type: 'success'
      })
      this.handleBackSubOrgList()

      window.location.reload()
    },
    updateOrg () {
      if (!this.owner.name || !this.org.name || !this.org.address) {
        alert('Bạn cần điền đầy đủ thông tin')
        return
      }
      this.putOrgList()
    },
    backOrg () {
      this.$router.push({
        path: appUtils.getOrgId() ? '/organization/management' : '/'
      })
    },
    handleBackSubOrgList () {
      this.$router.push({
        name: 'OrganizationManagement'
      })
    }
  }
}
</script>

<style scoped>
</style>
